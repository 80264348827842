<template>
  <div class="home d-flex flex-column align-center mx-4">
    <img class="ma-8"
      alt="Logo"
      src="../assets/logo-circle.svg"
      :width="isMobile? 128 : 256">
    <v-card class="ma-4"
      max-width="512"
      width="100%">
      <v-card-text>{{ $t('__home_apply_store_message') }}</v-card-text>
      <v-card-actions>
        <v-btn
          block
          color="primary"
          @click="applymStore">
          {{ $t('__home_apply_store') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card class="ma-4"
      max-width="512"
      width="100%">
      <v-card-text>{{ $t('__home_check_store_application_status_message') }}</v-card-text>
      <v-card-actions>
        <v-btn
          block
          color="primary"
          @click="checkStoreApplicationStatus">
          {{ $t('__home_check_store_application_status') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: 'Home',
  computed: {
    ...mapGetters({
      isMobile: "isMobile"
    })
  },
  methods: {
    applymStore() {
      this.$router.push({ name: 'ApplyStore' })
    },
    checkStoreApplicationStatus() {
      this.$router.push({ name: 'CreateStoreApplicationForms' })
    }
  }
}
</script>
